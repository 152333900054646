import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Safety from "../components/Solutions/Safety/Safety";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/safety.jpg"

const SafetyPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Safety - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="keywords" content="Car Toppers, GPS, franchises"/>
                <meta name="description"
                      content="Enhance last mile delivery driver safety with DrivosityCORE. Our car topper solutions track unsafe driving behaviors, incentivizing safer driving practices and leading to a reduction in insurance claims. Request a demo today!"/>

                <meta property="og:title" content="Safety - Drivosity"/>
                <meta property="og:description" content="Enhance last mile delivery driver safety with DrivosityCORE. Our car topper solutions track unsafe driving behaviors, incentivizing safer driving practices and leading to a reduction in insurance claims. Request a demo today!"/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Driver holding a smartphone with a navigation app open, representing road safety, GPS tracking, and route optimization."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/safety`}/>
            </Helmet>
            <Layout component={<Safety/>}/>
        </>
    )
}

export default SafetyPage;
